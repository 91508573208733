import { Trans, useTranslation } from 'react-i18next';
import TripleCardComponent from './TripleCardComponent/TripleCardComponent';
import RankListComponent from './RankListComponent/RankListComponent';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const getRandomPeople = function(){
    (async function() {
      const params = new URLSearchParams({"vote_guard": localStorage.getItem("voteGuard")});
      const url = `https://api.people-ranking.com/api/getPeople?${params.toString()}`;
      const response = await axios.get(url);    
      const data = response.data;

      navigate(`/people/${data.people_name.replaceAll(" ", "-")}-${data.people_id}`);
    })();
  }

  useEffect(() => {
    (async function() {
      try {
        const url = `https://api.people-ranking.com/api/getCategories`;
        const response = await axios.get(url);
        const data = response.data;

        if(data){
          setCategories(data);
        }
      } catch (error) {
        console.error('Error while fetching: ', error);
      }
    })();
  }, []);

  return (
      <div className="Home">
        <Helmet>
          <meta name="description" content={"Welcome to people ranking, the website that lists your celebrities. Find news, photos and vote for your favorite celebrities."} />
          <title>People ranking, vote for your favorite celebrities!</title>
        </Helmet>
        <div className="flex flex-col md:flex-row p-[20px] md:p-[50px]">
          <div className="w-full md:w-[50%] text-center md:text-left">
            <h1 className="leading-[3rem] text-[3rem] font-black"><Trans i18nKey="HomeComponent_h1"></Trans></h1>
            <p className='w-full md:w-[65%] text-lg font-medium mt-8'>{t('HomeComponent_subtitle')}</p>
            <button onClick={() => {getRandomPeople();}} className="mt-8 bg-[#0A1128] text-white text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow">
              Start to vote !
            </button>
          </div>
          <div className="w-full md:w-[50%]">
              <TripleCardComponent/>
          </div>
        </div>
        <div className='px-[50px] flex flex-wrap justify-center gap-3 w-full'>
          {categories.map((r,k) => {
            return (
              <div className="shadow-md rounded-md">
                <button key={k} onClick={() => {navigate(`/categorie/${r.cat_id}`)}} className="bg-slate-200 text-[#0A1128] text-sm border border-slate-200 rounded-md px-4 py-3 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow w-fit">
                  {r.cat_label_en}
                </button>
              </div>
            )
          })}
        </div>
        <div className='p-[50px] flex gap-4 flex-col md:flex-row md:w-full'>
          <RankListComponent rankType="most_liked" rankData="1"/>
          <RankListComponent rankType="most_disliked" rankData="-1"/>
          <RankListComponent rankType="most_trendy" rankData="2"/>
        </div>
      </div>
  );
}

export default Home;
